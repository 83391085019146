import React from "react";
import VideoThankyou from "../assets/4.mp4";

const Commited = () => {
  return (
    <div className="relative">
      <div className="h-screen w-screen mx-auto flex justify-center">
        <video
          id="video1"
          className="absolute h-full object-cover"
          autoPlay
          loop
          muted
        >
          <source src={VideoThankyou} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

export default Commited;
