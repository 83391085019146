import React from "react";
import VideoLoading from "../assets/1.mp4";

const LoadingScreen = () => {
  return (
    <div className="relative">
      <div className="h-screen w-screen mx-auto flex justify-center">
        <video
          id="video1"
          className="absolute h-full object-cover"
          autoPlay
          loop
          muted
        >
          <source src={VideoLoading} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

export default LoadingScreen;