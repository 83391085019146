import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import VideoCommitment1 from "../assets/2.mp4";
import VideoCommitment2 from "../assets/2-3.mp4";

const BtnPage = () => {
  const navigate = useNavigate();
  const [showVideo, setShowVideo] = useState(true)

  const handleClick = () => {
    setShowVideo(!showVideo);
    setTimeout(() => {
      navigate("/commited");
    },3000)
  };
  return (
    <div className="relative">
      <div className="h-screen w-screen mx-auto flex justify-center">
        <video
          id="video1"
          className={`absolute h-full object-cover ${showVideo ? '' : 'hidden'}`}
          autoPlay
          loop
          muted
          style={{ zIndex: -1 }}
        >
          <source src={VideoCommitment1} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video
          id="video2"
          className={`absolute h-full object-cover ${showVideo ? 'hidden' : ''}`}
          autoPlay
          loop
          muted
        >
          <source src={VideoCommitment2} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="flex items-center mt-10 justify-center ">
          <button
            className="w-60 h-[500px] font-bold rounded-full"
            onClick={handleClick}
          ></button>
        </div>
      </div>
    </div>
  );
};

export default BtnPage;
