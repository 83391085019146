import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import BtnPage from "./components/BtnPage";
import Commited from "./components/Commited";
import Loading from "./components/Loading";
import { useEffect, useState } from "react";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  },[])
  return (
    <BrowserRouter>
      {loading ? (<Loading />) : (<Routes>
        <Route path="/" exact element={<BtnPage />}/>
        <Route path="/commited" element={<Commited />}/>
      </Routes>
      )}
    </BrowserRouter>
  );
}

export default App;
